import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Moment from 'react-moment';

import {
    green,
    grey,
  } from '@material-ui/core/colors';
  
import { integrationActions } from '../_actions';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: '15px',
    },
    secondaryHeading: {
        fontSize: '15px',
        color: '#aaa',
    },
    break: {
      margin: '40px 0px'
    },
    column: {
      flexBasis: '33.33%',
    },
    tabletitle: {
        margin: '0px 0px 20px 0px'
    },
    code: {
        backgroundColor: '#eee',
    },
    logo: {
        height: '60px',
        width: 'auto'
    },
    integration_active: {
        color: '#fff',
        backgroundColor: green[500],
    },
    integration_inactive: {
        color: '#fff',
        backgroundColor: grey[400],
    },
    grayfont: {
        color: '#aaa'
    }
});

export default function IntegrationOverview() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const payor = useSelector(state => state.integration.payor || {});
    const integration_type = useSelector(state => state.integration.integration_type || '');
    const integration = useSelector(state => state.integration.integration || {});
    const webhooks = useSelector(state => state.integration.payor_webhooks || []);

    const [expanded, setExpanded] = React.useState('');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const serviceName = (integration) => {
        if (integration_type === 'quickbooks') {
            return 'QuickBooks ID: '+integration.realm_id;
        } else if (integration_type === 'xero') {
            return 'Xero ID: '+integration.tenant_id;
        }
        return '';
    }

    useEffect(() => {
        if (integration_type === 'quickbooks') {
            dispatch(integrationActions.getQuickbooksWebhooks(payor.payor_id, payor.id));
        } else if (integration_type === 'xero') {
            dispatch(integrationActions.getXeroWebhooks(payor.payor_id, payor.id));
        }
    }, [dispatch, integration_type, payor]);

    const renderActiveChip = (row) => {
        let label = row.is_active ? 'ACTIVE' : 'INACTIVE';
        let color = row.is_active ? classes.integration_active : classes.integration_inactive;
        return <Chip label={label} className={color} />
    }

    const renderLogo = () => {
        if (integration_type === 'quickbooks') {
            return <img alt="QuickBooks Logo" src="/img/quickbooks-logo-green.png" className={classes.logo} /> ;
        } else if (integration_type === 'xero') {
            return <img alt="Xero Logo" src="/img/xero-logo-blue.png" className={classes.logo} /> ;
        }
    }

    const renderNoRecords = (a) => {
        if (a.length === 0) {
            return <Typography variant="h6" component="h6" className={classes.grayfont}>No Inbound Webhooks Received.</Typography>;
        } else {
            return '';
        }
    }
    
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    {renderLogo()}
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="h6">
                        {integration.company_name}
                    </Typography>
                    <Typography variant="subtitle2" component="span">
                        {serviceName(integration)}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    {renderActiveChip(integration)}
                    <br/>
                    <Typography variant="subtitle2" component="span">
                        Created: <Moment date={new Date(integration.created_at)} format="MMMM DD, YYYY" />
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>

            <Divider className={classes.break} />

            {renderNoRecords(webhooks)}

            {webhooks.map(row => (
                <Accordion square key={row.id} expanded={expanded === row.id} onChange={handleChange(row.id)}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <div className={classes.column}>
                            <Typography className={classes.heading}>
                                <Moment date={new Date(row.created_at)} format="MMM DD, YYYY @ hh:mm:ss A ZZ" /> 
                            </Typography>
                        </div>
                        <div className={classes.column}>
                            <Typography className={classes.secondaryHeading}>{row.status}</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.code}>
                        <Typography>
                            {row.payload}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}