import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
    Snackbar,
    Chip,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    FormControlLabel,
    Button
} from '@material-ui/core';
import {
    Alert
} from '@material-ui/lab';
import Moment from 'react-moment';

import {
    green,
    grey,
} from '@material-ui/core/colors';

import { integrationActions } from '../_actions';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: '15px',
    },
    secondaryHeading: {
        fontSize: '15px',
        color: '#aaa',
    },
    break: {
        margin: '40px 0px'
    },
    column: {
        flexBasis: '33.33%',
    },
    tabletitle: {
        margin: '0px 0px 20px 0px'
    },
    code: {
        backgroundColor: '#eee',
    },
    logo: {
        height: '60px',
        width: 'auto'
    },
    integration_active: {
        color: '#fff',
        backgroundColor: green[500],
    },
    integration_inactive: {
        color: '#fff',
        backgroundColor: grey[400],
    },
    grayfont: {
        color: '#aaa'
    }
});

export default function Settings() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    // const payor = useSelector(state => state.integration.payor || {});
    const integration_type = useSelector(state => state.integration.integration_type || '');
    const integration = useSelector(state => state.integration.integration || {});
    const health = useSelector(state => state.integration.payor_health || {});


    const [open, setOpen] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarType, setSnackbarType] = useState('error');
    const [snackbarMsg, setSnackbarMsg] = useState('');

    const handleChange = (newstate) => {
        setState(newstate);
        if (integration_type === 'quickbooks') {
            dispatch(integrationActions.updateQuickbooksIntegration(integration.payor_id, integration.id, newstate));
            setShowSnackbar(true);
            setSnackbarMsg('Saving QuickBooks Integration');
            setSnackbarType('info');
        } else if (integration_type === 'xero') {
            dispatch(integrationActions.updateXeroIntegration(integration.payor_id, integration.id, newstate));
            setShowSnackbar(true);
            setSnackbarMsg('Saving Xero Integration');
            setSnackbarType('info');
        }
    };

    const [state, setState] = React.useState({
        is_active: integration.is_active,
        instruct_guard: integration.instruct_guard,
    });
    
    const serviceName = (integration) => {
        if (integration_type === 'quickbooks') {
            return 'QuickBooks ID: ' + integration.realm_id;
        } else if (integration_type === 'xero') {
            return 'Xero ID: ' + integration.tenant_id;
        }
        return '';
    };

    const renderActiveChip = (row) => {
        let label = row.is_active ? 'ACTIVE' : 'INACTIVE';
        let color = row.is_active ? classes.integration_active : classes.integration_inactive;
        return <Chip label={label} className={color} />
    };

    const renderLogo = () => {
        if (integration_type === 'quickbooks') {
            return <img alt="QuickBooks Logo" src="/img/quickbooks-logo-green.png" className={classes.logo} />;
        } else if (integration_type === 'xero') {
            return <img alt="Xero Logo" src="/img/xero-logo-blue.png" className={classes.logo} />;
        }
    };

    const SettingsForm = (props) => {
        const handleChange = (event) => {
            props.onChange({ ...state, [event.target.name]: event.target.checked });
        };

        return (<Grid item xs={12}>
            <FormControlLabel
                control={
                    <Switch
                        checked={props.payload.is_active}
                        onChange={handleChange}
                        name="is_active"
                        id="is_active"
                        color="primary"
                    />
                }
                label="Is Active"
            />
            <Typography variant="body2" gutterBottom>
                Is Active set to OFF will pause listening to inbound webhooks and NOT make payments.
                </Typography>
            <Typography variant="body2" gutterBottom>
                Is Active set to ON will enable listening to inbound webhooks and make payments.
                </Typography>

            <br />
            <Divider />
            <br />

            <FormControlLabel
                control={
                    <Switch
                        checked={props.payload.instruct_guard}
                        onChange={handleChange}
                        name="instruct_guard"
                        id="instruct_guard"
                        color="primary"
                    />
                }
                label="Instruct Guard"
            />
            <Typography variant="body2" gutterBottom>
                Instruct Guard set to OFF will submit, quote, &amp; instruct payments.
                </Typography>
            <Typography variant="body2" gutterBottom>
                Instruct Guard set to ON will submit &amp; quote payments. Allowing you to manually instruct payments.
                </Typography>

        </Grid>);
    };


    const handleClickRefreshToken = (pid) => {
        dispatch(integrationActions.generateVeloPayorToken(pid));
    };

    const renderVeloOAuth = (status) => {
        let s = status.velo_oauth_status;
        let p = JSON.parse(s.payload);
        let t = (p.status === 'success') ? 'success' : 'error';
        let m = (p.status === 'success') ? 'Successfully refreshed' : 'Error refreshing';
        return (<Grid item xs={12}>
            <br />
            <Alert severity={t}>
                <strong>Velo OAuth Status:</strong> {m} <Moment date={new Date(s.ts)} format="MMMM DD, YYYY hh:mm:ss a" />
            </Alert>
            <br />
            <Button color="primary" onClick={() => handleClickRefreshToken(s.payor_id)} variant="contained">Generate New Velo Token</Button>
        </Grid>);
    };

    const renderIntegrationOAuth = (status) => {
        let s = status.integration_oauth_status;
        let p = JSON.parse(s.payload);
        let t = (p.status === 'success') ? 'success' : 'error';
        let m = (p.status === 'success') ? 'Successfully refreshed' : 'Error refreshing';
        let l = (integration_type === 'quickbooks') ? 'QuickBooks' : 'Xero';
        return (<Grid item xs={12}>
            <br />
            <Divider />
            <br />
            <Alert severity={t}><strong>{l} OAuth Status:</strong> {m} <Moment date={new Date(s.ts)} format="MMMM DD, YYYY hh:mm:ss a" /></Alert>
        </Grid>);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (agree) => {
        if (agree) {
            dispatch(integrationActions.disconnectIntegration(integration.payor_id, integration_type, integration.id));
            setTimeout(() => {
                history.push('/');
                dispatch(integrationActions.getPayors());
            }, 1500);
        }
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    {renderLogo()}
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="h6">
                        {integration.company_name}
                    </Typography>
                    <Typography variant="subtitle2" component="span">
                        {serviceName(integration)}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    {renderActiveChip(integration)}
                    <br />
                    <Typography variant="subtitle2" component="span">
                        Created: <Moment date={new Date(integration.created_at)} format="MMMM DD, YYYY" />
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>

            <Divider className={classes.break} />

            <Grid container spacing={2}>
                <Typography variant="h6" component="h6">
                    General Settings
                </Typography>
                <SettingsForm payload={state} onChange={handleChange} />
            </Grid>

            <Divider className={classes.break} />

            <Grid container spacing={2}>
                <Typography variant="h6" component="h6">
                    OAuth Settings
                </Typography>
                {renderVeloOAuth(health)}
                {renderIntegrationOAuth(health)}
            </Grid>

            <Divider className={classes.break} />

            <Grid container spacing={2}>
                <Typography variant="h6" component="h6">
                    Integration Settings
                </Typography>
                <Grid item xs={12}>
                    <Button color="secondary" onClick={handleClickOpen} variant="contained">Disconnect Integration</Button>
                </Grid>
            </Grid>


            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={(event) => setShowSnackbar(false)} >
                <Alert severity={snackbarType}>{snackbarMsg}</Alert>
            </Snackbar>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Disconnect"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you with to disconnect your integration?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleClose(false)} color="secondary" variant="contained">
                    No
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" variant="contained" autoFocus>
                    Yes, Disconnect
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}