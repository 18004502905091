import { combineReducers } from 'redux';

import { app } from './app';
import { authentication } from './authentication';
import { integration } from './integration';

const rootReducer = combineReducers({
  app,
  authentication,
  integration
});

export default rootReducer;