import { authenticationService } from './authentication';
import { authHeader } from "../_helpers/auth-header";

export const integrationService = {
    getPayors,
    connectVeloPayor,
    generateVeloPayorToken,
    getQuickbooksAuthCode,
    getXeroAuthCode,
    getVeloPayorSourceAccounts,
    getQuickbooksAccounts,
    getXeroAccounts,
    updateQuickbooksIntegration,
    updateXeroIntegration,
    getQuickbooksIntegration,
    getXeroIntegration,
    getQuickbooksWebhooks,
    getXeroWebhooks,
    getQuickbooksMetrics,
    getXeroMetrics,
    getQuickbooksHealth,
    getXeroHealth,
    verifyQuickbooksIntegration,
    verifyXeroIntegration,
    createQuickbooksRule,
    deleteQuickbooksRule,
    createXeroRule,
    deleteXeroRule,
    getPayees,
    disconnectIntegration,
};


function getPayors() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function connectVeloPayor(payor_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        },
        body: JSON.stringify({ payor_id: payor_id })
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function generateVeloPayorToken(payor_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/velo/token`, requestOptions)
        .then(handleResponse)
        .then(() => {
            return;
        });
}

function getVeloPayorSourceAccounts(payor_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/velo/accounts`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload.accounts;
        });
}

function getQuickbooksAuthCode(payor_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/quickbooks/connect`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload.redirecturl;
        });
}

function getQuickbooksAccounts(payor_id, integration_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/quickbooks/${integration_id}/accounts`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload.accounts;
        });
}

function getQuickbooksIntegration(payor_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/quickbooks`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function updateQuickbooksIntegration(payor_id, integration_id, payload) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        },
        body: JSON.stringify(payload)
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/quickbooks/${integration_id}`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function getQuickbooksWebhooks(payor_id, id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/quickbooks/${id}/webhooks`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload.webhooks;
        });
}

function getQuickbooksMetrics(payor_id, id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/quickbooks/${id}/metrics`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function getQuickbooksHealth(payor_id, id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/quickbooks/${id}/health`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function verifyQuickbooksIntegration(payor_id) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/quickbooks/verify`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function createQuickbooksRule(payor_id, integration_id, payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        },
        body: JSON.stringify(payload)
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/quickbooks/${integration_id}/rules`, requestOptions)
        .then(handleResponse)
        .then(() => {
            return;
        });
}

function deleteQuickbooksRule(id, payor_id, integration_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/quickbooks/${integration_id}/rules/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => {
            return;
        });
}

function getXeroAuthCode(payor_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/xero/connect`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload.redirecturl;
        });
}

function getXeroAccounts(payor_id, integration_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/xero/${integration_id}/accounts`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload.accounts;
        });
}

function getXeroIntegration(payor_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/xero`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function updateXeroIntegration(payor_id, integration_id, payload) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        },
        body: JSON.stringify(payload)
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/xero/${integration_id}`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function getXeroWebhooks(payor_id, id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/xero/${id}/webhooks`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload.webhooks;
        });
}

function getXeroMetrics(payor_id, id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/xero/${id}/metrics`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function getXeroHealth(payor_id, id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/xero/${id}/health`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function verifyXeroIntegration(payor_id) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/xero/verify`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function createXeroRule(payor_id, integration_id, payload) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        },
        body: JSON.stringify(payload)
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/xero/${integration_id}/rules`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function deleteXeroRule(id, payor_id, integration_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/xero/${integration_id}/rules/${id}`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function getPayees(payor_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/payees`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload.payees;
        });
}

function disconnectIntegration(payor_id, integration_type, integration_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `${authHeader().Authorization}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/payors/${payor_id}/${integration_type}/${integration_id}`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}


function handleResponse(response) {
    if (response.status === 401) {
        authenticationService.logout();
    }
    return response.json().then(data => {
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    },
    error => {
        return;
    });
}
