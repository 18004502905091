import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../_reducers';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ; 

export default createStore(
    rootReducer,
    // composeEnhancers( //
        applyMiddleware(
            thunkMiddleware
        )
    // )
);
