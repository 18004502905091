import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    Tooltip,
    TableHead,
    TableRow,
    Typography,
    Select,
    Snackbar,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import {
    Alert
} from '@material-ui/lab';
import {
    Add,
    Clear,
    Help,
    Save,
} from '@material-ui/icons';
import {
    green,
    grey,
} from '@material-ui/core/colors';

import { integrationActions } from '../_actions';

const useStyles = makeStyles({
    root: {
        marginTop: '20px',
        width: '90%',
    },
    heading: {
        fontSize: '15px',
    },
    secondaryHeading: {
        fontSize: '15px',
        color: '#aaa',
    },
    break: {
        margin: '40px 0px'
    },
    column: {
        flexBasis: '33.33%',
    },
    tabletitle: {
        margin: '0px 0px 20px 0px'
    },
    code: {
        backgroundColor: '#eee',
    },
    logo: {
        height: '60px',
        width: 'auto'
    },
    integration_active: {
        color: '#fff',
        backgroundColor: green[500],
    },
    integration_inactive: {
        color: '#fff',
        backgroundColor: grey[400],
    },
    rulestitle: {
        textAlign: 'left'
    },
    grayfont: {
        color: '#aaa'
    }
});

export default function Rules(props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const integration_type = props.type;
    const integration = props.integration;

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarType, setSnackbarType] = useState('error');
    const [snackbarMsg, setSnackbarMsg] = useState('');

    useEffect(() => {
        dispatch(integrationActions.getVeloPayorSourceAccounts(integration.payor_id));
        if (integration_type === 'quickbooks') {
            dispatch(integrationActions.getQuickbooksAccounts(integration.payor_id, integration.id));
        } else {
            dispatch(integrationActions.getXeroAccounts(integration.payor_id, integration.id));
        }
        
    }, [dispatch, integration, integration_type]);

////////////////////////////////////////////////////////////////////////////////

    const veloSourceAccounts = useSelector(state => state.integration.payor_velo_source_accounts);
    const [veloQuickbooksSourceAccount, setVeloQuickbooksSourceAccount] = React.useState();
    const [veloXeroSourceAccount, setVeloXeroSourceAccount] = React.useState();
    const [apAccountId, setAPAccountId] = React.useState('');

    const renderVeloSourceAccount = (row, payor_id) => {
        const act = veloSourceAccounts.find(a => a.name === row.velo_source_account);

        return (act)? row.velo_source_account+' - '+act.currency : '';
    }

////////////////////////////////////////////////////////////////////////////////

    const [openhelp, setOpenHelp] = React.useState(false);
    const handleClickOpenHelp = (type) => {
        setOpenHelp(true);
    };
    const handleCloseHelp = () => {
        setOpenHelp(false);
    };

////////////////////////////////////////////////////////////////////////////////

    const quickbooksAccounts = useSelector(state => state.integration.payor_quickbooks_accounts);
    const [showQuickbooksForm, setShowQuickbooksForm] = React.useState(false);

    const renderQuickBooksAPAccount = (row, payor_id, integration_id) => {
        let accountArray = quickbooksAccounts[integration_id] || [];
        if (accountArray.length === 0) {
            
        }
        const act = accountArray.find(a => a.Id === row.ap_account_id);

        return (act)? act.Name+' - '+act.CurrencyRef.value : '';
    }

    const renderQuickbooksRules = (payor_id, integration_id, rules, integration) => {
        if (!integration.is_active || integration_type !== 'quickbooks') {
            return;
        }
        return (

            <Grid container spacing={2} className={classes.tablecontainer}>
                <Grid item xs={10} className={classes.rulestitle} >
                    <Typography variant="h6" component="span">
                        Integration Rules
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title="Help" arrow>
                        <IconButton color="primary" aria-label="rules help" size="small" component="span" onClick={() => handleClickOpenHelp('quickbooks')}>
                            <Help />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add New Rule" arrow>
                        <IconButton color="primary" aria-label="add rule" component="span" onClick={() => showQuickbooksRuleForm(payor_id, integration_id)}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    
                    <Table className={classes.table} aria-label="payors">
                        <TableHead>
                            <TableRow>
                                <TableCell>Velo Source Account</TableCell>
                                <TableCell>QuickBooks AP Account</TableCell>
                                <TableCell align="right">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rules.map(row => {
                                if (!row.is_deleted) {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">
                                                {renderVeloSourceAccount(row, payor_id)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {renderQuickBooksAPAccount(row, payor_id, integration_id)}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton color="secondary" aria-label="remove rule" component="span" onClick={() => deleteQuickbooksRule(row.id, payor_id, integration_id)}>
                                                    <Clear />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                } else {
                                    return <TableRow key="na"></TableRow>;
                                }
                            })}
                            {renderQuickbooksForm(payor_id, integration_id, rules)}
                        </TableBody>
                    </Table>

                </Grid>
            </Grid>
        );
    }

    const deleteQuickbooksRule = (id, payor_id, integration_id) => {
        dispatch(integrationActions.deleteQuickbooksRule(id, payor_id, integration_id));
        setShowQuickbooksForm(false);
        setVeloQuickbooksSourceAccount('');
        setAPAccountId('');
    }

    const createQuickbooksRule = (payor_id, integration_id, rules) => {
        if (apAccountId.trim() === "" || veloQuickbooksSourceAccount.trim() === "") {
            setShowSnackbar(true);
            setSnackbarMsg('Please select both values for both dropdowns.');
            setSnackbarType('error');
        } else if (! rules.find(r => (r.is_deleted === false && r.ap_account_id === apAccountId.trim()))) {
            let payload = { "velo_source_account" : veloQuickbooksSourceAccount.trim(), "ap_account_id": apAccountId.trim() };
            dispatch(integrationActions.createQuickbooksRule(payor_id, integration_id, payload));
            setShowQuickbooksForm(false);
            setVeloQuickbooksSourceAccount('');
            setAPAccountId('');
        } else  {
            setShowSnackbar(true);
            setSnackbarMsg('Can not have duplicate AP Account');
            setSnackbarType('error');
        }
    }

    const showQuickbooksRuleForm = (payor_id, integration_id) => {
        dispatch(integrationActions.getVeloPayorSourceAccounts(payor_id));
        dispatch(integrationActions.getQuickbooksAccounts(payor_id, integration_id));
        setShowQuickbooksForm(true);
    }

    const renderQuickbooksForm = (payor_id, integration_id, rules) => {
        if (showQuickbooksForm) {
            return <TableRow>
                <TableCell component="th" scope="row">
                    <Select
                        id="demo-simple-select"
                        value={veloQuickbooksSourceAccount}
                        onChange={(event) => setVeloQuickbooksSourceAccount(event.target.value)}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="" disabled>  -  </MenuItem>
                        {veloSourceAccounts.map(row => (
                            <MenuItem key={row.id} value={row.name}>{row.name} ({row.accountType})</MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Select
                        id="demo-simple-select"
                        value={apAccountId}
                        onChange={(event) => setAPAccountId(event.target.value)}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="" disabled> - </MenuItem>
                        {(quickbooksAccounts[integration_id] || []).map(row => (
                        <MenuItem key={row.Id} value={row.Id}>{row.Name}</MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell align="right">
                    <IconButton color="primary" aria-label="add rule" component="span" onClick={() => createQuickbooksRule(payor_id, integration_id, rules)}>
                        <Save />
                    </IconButton>
                </TableCell>
            </TableRow>;
        }
    }

////////////////////////////////////////////////////////////////////////////////

    const xeroAccounts = useSelector(state => state.integration.payor_xero_accounts);
    const [showXeroForm, setShowXeroForm] = React.useState(false);

    const renderXeroAPAccount = (row, payor_id, integration_id) => {
        let accountArray = xeroAccounts[integration_id] || [];
        if (accountArray.length === 0) {
            return 
        }

        const act = accountArray.find(a => a.code === row.ap_account_id);

        return (act)? act.name : '';
    }

    const renderXeroRules = (payor_id, integration_id, rules, integration) => {
        if (!integration.is_active || integration_type !== 'xero') {
            return;
        }
        return (
            <Grid container spacing={2} className={classes.tablecontainer}>
                <Grid item xs={10} className={classes.rulestitle} >
                    <Typography variant="h6" component="span">
                        Integration Rules
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title="Help" arrow>
                        <IconButton color="primary" aria-label="rules help" size="small" component="span" onClick={() => handleClickOpenHelp('xero')}>
                            <Help />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add New Rule" arrow>
                        <IconButton color="primary" aria-label="add rule" component="span" onClick={() => showXeroRuleForm(payor_id, integration_id)}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Table className={classes.table} aria-label="payors">
                        <TableHead>
                            <TableRow>
                                <TableCell>Velo Source Account</TableCell>
                                <TableCell>Xero AP Account</TableCell>
                                <TableCell align="right">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rules.map(row => {
                                if (!row.is_deleted) {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">
                                                {renderVeloSourceAccount(row, payor_id)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {renderXeroAPAccount(row, payor_id, integration_id)}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton color="secondary" aria-label="remove rule" component="span" onClick={() => deleteXeroRule(row.id, payor_id, integration_id)}>
                                                    <Clear />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                } else {
                                    return <TableRow key="na"></TableRow>;
                                }
                            })}
                            {renderXeroForm(payor_id, integration_id, rules)}
                        </TableBody>
                    </Table>

                </Grid>
            </Grid>
        );
    }

    const deleteXeroRule = (id, payor_id, integration_id) => {
        dispatch(integrationActions.deleteXeroRule(id, payor_id, integration_id));
        setShowXeroForm(false);
        setVeloXeroSourceAccount('');
        setAPAccountId('');
    }

    const createXeroRule = (payor_id, integration_id, rules) => {
        if (apAccountId.trim() === "" || veloXeroSourceAccount.trim() === "") {
            setShowSnackbar(true);
            setSnackbarMsg('Please select both values for both dropdowns.');
            setSnackbarType('error');
        } else if (! rules.find(r => (r.is_deleted === false && r.ap_account_id === apAccountId.trim()) )) {
            let payload = { "velo_source_account" : veloXeroSourceAccount.trim(), "ap_account_id": apAccountId.trim() };
            dispatch(integrationActions.createXeroRule(payor_id, integration_id, payload));
            setShowXeroForm(false);
            setVeloXeroSourceAccount('');
            setAPAccountId('');
        } else {
            setShowSnackbar(true);
            setSnackbarMsg('Can not have duplicate AP Account');
            setSnackbarType('error');
        }
    }

    const renderXeroForm = (payor_id, integration_id, rules) => {
        if (showXeroForm) {
            return <TableRow>
                <TableCell component="th" scope="row">
                    <Select
                        id="demo-simple-select"
                        value={veloXeroSourceAccount}
                        onChange={(event) => setVeloXeroSourceAccount(event.target.value)}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="" disabled>  -  </MenuItem>
                        {veloSourceAccounts.map(row => (
                            <MenuItem key={row.id} value={row.name}>{row.name} ({row.accountType})</MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Select
                        id="demo-simple-select"
                        value={apAccountId}
                        onChange={(event) => setAPAccountId(event.target.value)}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="" disabled> - </MenuItem>
                        {(xeroAccounts[integration_id] || []).map(row => (
                        <MenuItem key={row.code} value={row.code}>{row.name}</MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell align="right">
                    <IconButton color="primary" aria-label="add rule" component="span" onClick={() => createXeroRule(payor_id, integration_id, rules)}>
                        <Save />
                    </IconButton>
                </TableCell>
            </TableRow>;
        }
    }

    const showXeroRuleForm = (payor_id, integration_id) => {
        dispatch(integrationActions.getVeloPayorSourceAccounts(payor_id));
        dispatch(integrationActions.getXeroAccounts(payor_id, integration_id));
        setShowXeroForm(true);
    }

////////////////////////////////////////////////////////////////////////////////



    return (
        <div className={classes.root}>

            {renderQuickbooksRules(integration.payor_id, integration.id, integration.xero_rules, integration)}
            
            {renderXeroRules(integration.payor_id, integration.id, integration.xero_rules, integration)}

            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={(event) => setShowSnackbar(false)} >
                <Alert severity={snackbarType}>{snackbarMsg}</Alert>
            </Snackbar>

            <Dialog
                open={openhelp}
                onClose={handleCloseHelp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"How Rules Work"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <ul>
                            <li>rules allow the system to know what 3rd party transactions to listen for</li>
                            <li>it also allows us to map that transaction to a Velo source account</li>
                            <li>you can have multiple rules</li>
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleCloseHelp()} color="primary" variant="outlined">
                    Close
                </Button>
                </DialogActions>
            </Dialog>
            
        </div>
    );
}