export const authenticationConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    MFA_REQUEST: 'MFA_REQUEST',
    MFA_SUCCESS: 'MFA_SUCCESS',
    MFA_FAILURE: 'MFA_FAILURE',
    REFRESH_REQUEST: 'REFRESH_REQUEST',
    REFRESH_SUCCESS: 'REFRESH_SUCCESS',
    REFRESH_FAILURE: 'REFRESH_FAILURE',
    SELF_REQUEST: 'SELF_REQUEST',
    SELF_SUCCESS: 'SELF_SUCCESS',
    SELF_FAILURE: 'SELF_FAILURE',
    LOGOUT: 'LOGOUT'  
};
