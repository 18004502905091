import { authenticationConstants } from '../_constants';
import { authenticationService } from '../_services';

export const authenticationActions = {
    login,
    mfa,
    logout,
    refresh,
    getSelf,
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        authenticationService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: authenticationConstants.LOGIN_REQUEST, user }; }
    function success(user) { return { type: authenticationConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: authenticationConstants.LOGIN_FAILURE, error } }
}

function mfa(token) {
    return dispatch => {
        dispatch(request());
        
        authenticationService.mfa(token)
            .then(
                user => { 
                    dispatch(success(user));
                    dispatch(getSelf());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: authenticationConstants.MFA_REQUEST } }
    function success(user) { return { type: authenticationConstants.MFA_SUCCESS, user } }
    function failure(error) { return { type: authenticationConstants.MFA_FAILURE, error } }
}

function logout(auto) {
    return dispatch => {
        dispatch(request());

        authenticationService.logout(auto);
    };
    ;
    function request() { return { type: authenticationConstants.LOGOUT } }
}

function refresh() {
    return dispatch => {
        dispatch(request());

        authenticationService.refresh()
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: authenticationConstants.REFRESH_REQUEST }; }
    function success(user) { return { type: authenticationConstants.REFRESH_SUCCESS, user } }
    function failure(error) { return { type: authenticationConstants.REFRESH_FAILURE, error } }
}

function getSelf() {
    return dispatch => {
        dispatch(request());

        authenticationService.getSelf()
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: authenticationConstants.SELF_REQUEST }; }
    function success(user) { return { type: authenticationConstants.SELF_SUCCESS, user } }
    function failure(error) { return { type: authenticationConstants.SELF_FAILURE, error } }
}
