export const integrationConstants = {
    GETPAYORS_REQUEST: 'GETPAYORS_REQUEST',
    GETPAYORS_SUCCESS: 'GETPAYORS_SUCCESS',
    GETPAYORS_FAILURE: 'GETPAYORS_FAILURE',
    
    CONNECTVELOPAYOR_REQUEST: 'CONNECTVELOPAYOR_REQUEST',
    CONNECTVELOPAYOR_SUCCESS: 'CONNECTVELOPAYOR_SUCCESS',
    CONNECTVELOPAYOR_FAILURE: 'CONNECTVELOPAYOR_FAILURE',

    GENERATEVELOTOKEN_REQUEST: 'GENERATEVELOTOKEN_REQUEST',
    GENERATEVELOTOKEN_SUCCESS: 'GENERATEVELOTOKEN_SUCCESS',
    GENERATEVELOTOKEN_FAILURE: 'GENERATEVELOTOKEN_FAILURE',

    GETQBAUTHCODE_REQUEST: 'GETQBAUTHCODE_REQUEST',
    GETQBAUTHCODE_SUCCESS: 'GETQBAUTHCODE_SUCCESS',
    GETQBAUTHCODE_FAILURE: 'GETQBAUTHCODE_FAILURE',

    GETXEROAUTHCODE_REQUEST: 'GETXEROAUTHCODE_REQUEST',
    GETXEROAUTHCODE_SUCCESS: 'GETXEROAUTHCODE_SUCCESS',
    GETXEROAUTHCODE_FAILURE: 'GETXEROAUTHCODE_FAILURE',

    GETVELOPAYORSOURCEACCOUNTS_REQUEST: 'GETVELOPAYORSOURCEACCOUNTS_REQUEST',
    GETVELOPAYORSOURCEACCOUNTS_SUCCESS: 'GETVELOPAYORSOURCEACCOUNTS_SUCCESS',
    GETVELOPAYORSOURCEACCOUNTS_FAILURE: 'GETVELOPAYORSOURCEACCOUNTS_FAILURE',
    
    GETQUICKBOOKSACCOUNTS_REQUEST: 'GETQUICKBOOKSACCOUNTS_REQUEST',
    GETQUICKBOOKSACCOUNTS_SUCCESS: 'GETQUICKBOOKSACCOUNTS_SUCCESS',
    GETQUICKBOOKSACCOUNTS_FAILURE: 'GETQUICKBOOKSACCOUNTS_FAILURE',
    
    GETXEROACCOUNTS_REQUEST: 'GETXEROACCOUNTS_REQUEST',
    GETXEROACCOUNTS_SUCCESS: 'GETXEROACCOUNTS_SUCCESS',
    GETXEROACCOUNTS_FAILURE: 'GETXEROACCOUNTS_FAILURE',
    
    UPDATEQUICKBOOKSINTEGRATION_REQUEST: 'UPDATEQUICKBOOKSINTEGRATION_REQUEST',
    UPDATEQUICKBOOKSINTEGRATION_SUCCESS: 'UPDATEQUICKBOOKSINTEGRATION_SUCCESS',
    UPDATEQUICKBOOKSINTEGRATION_FAILURE: 'VQUICKBOOKSINTEGRATION_FAILURE',
    
    UPDATEXEROINTEGRATION_REQUEST: 'UPDATEXEROINTEGRATION_REQUEST',
    UPDATEXEROINTEGRATION_SUCCESS: 'UPDATEXEROINTEGRATION_SUCCESS',
    UPDATEXEROINTEGRATION_FAILURE: 'UPDATEXEROINTEGRATION_FAILURE',
    
    GETQUICKBOOKSINTEGRATION_REQUEST: 'GETQUICKBOOKSINTEGRATION_REQUEST',
    GETQUICKBOOKSINTEGRATION_SUCCESS: 'GETQUICKBOOKSINTEGRATION_SUCCESS',
    GETQUICKBOOKSINTEGRATION_FAILURE: 'GETQUICKBOOKSINTEGRATION_FAILURE',
    
    GETXEROINTEGRATION_REQUEST: 'GETXEROINTEGRATION_REQUEST',
    GETXEROINTEGRATION_SUCCESS: 'GETXEROINTEGRATION_SUCCESS',
    GETXEROINTEGRATION_FAILURE: 'GETXEROINTEGRATION_FAILURE',
    
    GETXEROWEBHOOKS_REQUEST: 'GETXEROWEBHOOKS_REQUEST',
    GETXEROWEBHOOKS_SUCCESS: 'GETXEROWEBHOOKS_SUCCESS',
    GETXEROWEBHOOKS_FAILURE: 'GETXEROWEBHOOKS_FAILURE',
    
    GETQUICKBOOKSWEBHOOKS_REQUEST: 'GETQUICKBOOKSWEBHOOKS_REQUEST',
    GETQUICKBOOKSWEBHOOKS_SUCCESS: 'GETQUICKBOOKSWEBHOOKS_SUCCESS',
    GETQUICKBOOKSWEBHOOKS_FAILURE: 'GETQUICKBOOKSWEBHOOKS_FAILURE',
    
    GETXEROMETRICS_REQUEST: 'GETXEROMETRICS_REQUEST',
    GETXEROMETRICS_SUCCESS: 'GETXEROMETRICS_SUCCESS',
    GETXEROMETRICS_FAILURE: 'GETXEROMETRICS_FAILURE',
    
    GETQUICKBOOKSMETRICS_REQUEST: 'GETQUICKBOOKSMETRICS_REQUEST',
    GETQUICKBOOKSMETRICS_SUCCESS: 'GETQUICKBOOKSMETRICS_SUCCESS',
    GETQUICKBOOKSMETRICS_FAILURE: 'GETQUICKBOOKSMETRICS_FAILURE',
    
    GETXEROHEALTH_REQUEST: 'GETXEROHEALTH_REQUEST',
    GETXEROHEALTH_SUCCESS: 'GETXEROHEALTH_SUCCESS',
    GETXEROHEALTH_FAILURE: 'GETXEROHEALTH_FAILURE',
    
    GETQUICKBOOKSHEALTH_REQUEST: 'GETQUICKBOOKSHEALTH_REQUEST',
    GETQUICKBOOKSHEALTH_SUCCESS: 'GETQUICKBOOKSHEALTH_SUCCESS',
    GETQUICKBOOKSHEALTH_FAILURE: 'GETQUICKBOOKSHEALTH_FAILURE',
    
    VERIFYQUICKBOOKSINTEGRATION_REQUEST: 'VERIFYQUICKBOOKSINTEGRATION_REQUEST',
    VERIFYQUICKBOOKSINTEGRATION_SUCCESS: 'VERIFYQUICKBOOKSINTEGRATION_SUCCESS',
    VERIFYQUICKBOOKSINTEGRATION_FAILURE: 'VERIFYQUICKBOOKSINTEGRATION_FAILURE',
    
    VERIFYXEROINTEGRATION_REQUEST: 'VERIFYXEROINTEGRATION_REQUEST',
    VERIFYXEROINTEGRATION_SUCCESS: 'VERIFYXEROINTEGRATION_SUCCESS',
    VERIFYXEROINTEGRATION_FAILURE: 'VERIFYXEROINTEGRATION_FAILURE',
    
    SETPAYOR_CONFIGURE: 'SETPAYOR_CONFIGURE',

    SETINTEGRATION_TAB: 'SETINTEGRATION_TAB',
    
    CREATEQUICKBOOKSRULE_REQUEST: 'CREATEQUICKBOOKSRULE_REQUEST',
    CREATEQUICKBOOKSRULE_SUCCESS: 'CREATEQUICKBOOKSRULE_SUCCESS',
    CREATEQUICKBOOKSRULE_FAILURE: 'CREATEQUICKBOOKSRULE_FAILURE',
    
    DELETEQUICKBOOKSRULE_REQUEST: 'DELETEQUICKBOOKSRULE_REQUEST',
    DELETEQUICKBOOKSRULE_SUCCESS: 'DELETEQUICKBOOKSRULE_SUCCESS',
    DELETEQUICKBOOKSRULE_FAILURE: 'DELETEQUICKBOOKSRULE_FAILURE',
    
    CREATEXERORULE_REQUEST: 'CREATEXERORULE_REQUEST',
    CREATEXERORULE_SUCCESS: 'CREATEXERORULE_SUCCESS',
    CREATEXERORULE_FAILURE: 'CREATEXERORULE_FAILURE',
    
    DELETEXERORULE_REQUEST: 'DELETEXERORULE_REQUEST',
    DELETEXERORULE_SUCCESS: 'DELETEXERORULE_SUCCESS',
    DELETEXERORULE_FAILURE: 'DELETEXERORULE_FAILURE',

    GETPAYEES_REQUEST: 'GETPAYEES_REQUEST',
    GETPAYEES_SUCCESS: 'GETPAYEES_SUCCESS',
    GETPAYEES_FAILURE: 'GETPAYEES_FAILURE',

    GENERATEVELOPAYORTOKEN_REQUEST: 'GENERATEVELOPAYORTOKEN_REQUEST',
    GENERATEVELOPAYORTOKEN_SUCCESS: 'GENERATEVELOPAYORTOKEN_SUCCESS',
    GENERATEVELOPAYORTOKEN_FAILURE: 'GENERATEVELOPAYORTOKEN_FAILURE',

    DISCONNECTINTEGRATION_REQUEST: 'DISCONNECTINTEGRATION_REQUEST',
    DISCONNECTINTEGRATION_SUCCESS: 'DISCONNECTINTEGRATION_SUCCESS',
    DISCONNECTINTEGRATION_FAILURE: 'DISCONNECTINTEGRATION_FAILURE',    
};
