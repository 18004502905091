import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Collapse,
  Typography,
  Button,
  Grid,
  TextField,
  Paper
} from "@material-ui/core";
import {
  Alert,
} from "@material-ui/lab";
import { authenticationActions } from '../_actions';
import logo from '../logo.svg';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f8f9fa",
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  panel: {
    backgroundColor: "#fff",
    padding: '56px 0px 0px 0px',
  },
  logo: {
    height: '40px',
  },
  title: {
    margin: '32px 0px 16px 0px',
    fontWeight: '500',
    fontSize: '24px',
    fontFamily: '"Benton Sans", Arial, sans-serif'
  },
  erroralert: {
    margin: '12px 0px 12px 0px'
  },
  submitbtn: {
    margin: '6px 0px 0px 0px'
  },
  footer: {
    margin: '56px 0px 0px 0px',
    backgroundColor: '#f8f9fa',
    padding: '8px 0px'
  },
  footerlink: {
    color: '#0077ba'
  },
  formcontent: {
    margin: '0px 32px'
  },
  footercopy: {
    fontSize: '14px'
  }
}));

export function MFA() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const verifyingMFA = useSelector(state => state.authentication.verifyingMFA);
  const mfaReady = useSelector(state => state.authentication.mfaReady);
  const mfaType = useSelector(state => state.authentication.mfaType);
  const authFailure = useSelector(state => state.authentication.authFailure);
  const [loginAttempted, setLoginAttempted] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  
  const [mfaOTP, setMfaOTP] = useState(null);

  if (loggedIn) {
    return <Redirect to='/' />
  } else if (!mfaReady) {
    return <Redirect to='/login' />
  }

  const validateMFA = () => {
    setLoginAttempted(true);
    dispatch(authenticationActions.mfa(mfaOTP));
  }
  
  const handleMFAChange = (e) => setMfaOTP(e.currentTarget.value.trim())

  const mfaOptions = {
    SMS: {
      label: '6-digit code',
      helpText: 'Please enter a 6-digit code.'
    },
    YUBIKEY: {
      label: 'YubiKey Code',
      helpText: 'Please insert and activate your YubiKey.'
    },
    TOTP: {
      label: '6-digit code',
      helpText: 'Please enter a 6-digit code.'
    }
  };

  if (loginAttempted && authFailure) {
    setLoginAttempted(false);
    setOpenAlert(true);
  }

  const keyPress = (e) => {
    if(e.keyCode === 13){
      validateMFA();
    }
 }
  return (
    <Grid container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}>
        <Grid item xs={4}>
          <Paper elevation={3} className={classes.panel}>
          <Grid item xs={12}>
            <img src={logo} alt="Logo" className={classes.logo} />
            <Typography variant="h5" className={classes.title}>
              Verification Code
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography use="bodyText" key="bodyText" tag="div">
              {mfaOptions[mfaType].helpText}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.formcontent}>
            <Grid container justify="center">
              <Grid item xs={12}>
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="mfavalue"
                  label={mfaOptions[mfaType].label}
                  name="mfavalue"
                  onChange={handleMFAChange}
                  onKeyDown={keyPress}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <Collapse className={classes.erroralert} in={openAlert}>
                  <Alert  severity="error">Failed to verify. Please try again.</Alert>
                </Collapse>
                <Button variant="contained" color="primary" disabled={verifyingMFA} fullWidth onClick={validateMFA}>Verify</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.footer}>
            <Grid container style={{ width: "100%", margin: "0px 0px 10px 0px" }}>
              <Grid item
                xs={4}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <a className={classes.footerlink} href="https://www.velopayments.com/payorterms" rel="noopener noreferrer" target="_blank">Terms of Use</a>
              </Grid>
              <Grid item
                xs={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <a className={classes.footerlink} href="https://www.velopayments.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
              </Grid>
              <Grid item
                xs={4}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <a className={classes.footerlink} href="https://support.velopayments.com/hc/en-us/requests/new" rel="noopener noreferrer" target="_blank">Contact Us</a>
              </Grid>
            </Grid>

            <Typography use="footnote" key="footnote" tag="div" className={classes.footercopy}>
              {'© Copyright'}
              {' '}
              {new Date().getFullYear()}
              {' Velo Payments. All rights reserved.'}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}