import { integrationConstants } from '../_constants';

const initialState = { 
  payors: [], 
  integration_type: null, 
  integration: null, 
  payor: null,  
  integration_tab: 0, 
  qb_redirecturl: null, 
  xero_redirecturl: null,
  payor_velo_source_accounts: [],
  payor_quickbooks_accounts: {},
  payor_xero_accounts: {},
  payor_quickbooks_integration: null,
  payor_xero_integration: null,
  payor_webhooks: [],
  payor_metrics: [],
  payor_health: [],
  payor_quickbooks_verify: null,
  payor_xero_verify: null, 
  payor_payees: [],
};

export function integration(state = initialState, action) {
  switch (action.type) {
    //
    case integrationConstants.GETPAYORS_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETPAYORS_SUCCESS:
      return Object.assign({}, state, {
        payors: action.payors, 
      });
    case integrationConstants.GETPAYORS_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.SETPAYOR_CONFIGURE:
      return Object.assign({}, state, {
        payor: action.payor,
        integration_type: action.integration_type,
        integration: action.payor
      });
    //
    case integrationConstants.GETQBAUTHCODE_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETQBAUTHCODE_SUCCESS:
      return Object.assign({}, state, {
        qb_redirecturl: action.redirecturl, 
      });
    case integrationConstants.GETQBAUTHCODE_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETXEROAUTHCODE_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETXEROAUTHCODE_SUCCESS:
      return Object.assign({}, state, {
        xero_redirecturl: action.redirecturl, 
      });
    case integrationConstants.GETXEROAUTHCODE_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.CONNECTVELOPAYOR_REQUEST:
      return Object.assign({}, state, {
        config_payor_step: 0,
      });
    case integrationConstants.CONNECTVELOPAYOR_SUCCESS:
      return Object.assign({}, state, {
        config_payor_step: 1, 
        payor: action.payor
      });
    case integrationConstants.CONNECTVELOPAYOR_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETVELOPAYORSOURCEACCOUNTS_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETVELOPAYORSOURCEACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        payor_velo_source_accounts: action.accounts, 
      });
    case integrationConstants.GETVELOPAYORSOURCEACCOUNTS_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETQUICKBOOKSACCOUNTS_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETQUICKBOOKSACCOUNTS_SUCCESS:
      let pqa = state.payor_quickbooks_accounts;
      pqa[action.integration_id] = action.accounts;
      return Object.assign({}, state, {
        payor_quickbooks_accounts: pqa, 
      });
    case integrationConstants.GETQUICKBOOKSACCOUNTS_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETXEROACCOUNTS_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETXEROACCOUNTS_SUCCESS:
      let pxa = state.payor_xero_accounts;
      pxa[action.integration_id] = action.accounts;
      return Object.assign({}, state, {
        payor_xero_accounts: pxa, 
      });
    case integrationConstants.GETXEROACCOUNTS_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETQUICKBOOKSINTEGRATION_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETQUICKBOOKSINTEGRATION_SUCCESS:
      return Object.assign({}, state, {
        payor_quickbooks_integration: action.integration, 
      });
    case integrationConstants.GETQUICKBOOKSINTEGRATION_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETXEROINTEGRATION_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETXEROINTEGRATION_SUCCESS:
      return Object.assign({}, state, {
        payor_xero_integration: action.integration, 
      });
    case integrationConstants.GETXEROINTEGRATION_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETQUICKBOOKSWEBHOOKS_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETQUICKBOOKSWEBHOOKS_SUCCESS:
      return Object.assign({}, state, {
        payor_webhooks: action.webhooks, 
      });
    case integrationConstants.GETQUICKBOOKSWEBHOOKS_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETXEROWEBHOOKS_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETXEROWEBHOOKS_SUCCESS:
      return Object.assign({}, state, {
        payor_webhooks: action.webhooks, 
      });
    case integrationConstants.GETXEROWEBHOOKS_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.VERIFYQUICKBOOKSINTEGRATION_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.VERIFYQUICKBOOKSINTEGRATION_SUCCESS:
      return Object.assign({}, state, {
        payor_quickbooks_verify: action.verify, 
      });
    case integrationConstants.VERIFYQUICKBOOKSINTEGRATION_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.VERIFYXEROINTEGRATION_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.VERIFYXEROINTEGRATION_SUCCESS:
      return Object.assign({}, state, {
        payor_xero_verify: action.verify, 
      });
    case integrationConstants.VERIFYXEROINTEGRATION_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.SETINTEGRATION_TAB:
      return Object.assign({}, state, {
        integration_tab: action.tab
      });
    //
    case integrationConstants.GETQUICKBOOKSMETRICS_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETQUICKBOOKSMETRICS_SUCCESS:
      return Object.assign({}, state, {
        payor_metrics: action.metrics, 
      });
    case integrationConstants.GETQUICKBOOKSMETRICS_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETXEROMETRICS_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETXEROMETRICS_SUCCESS:
      return Object.assign({}, state, {
        payor_metrics: action.metrics, 
      });
    case integrationConstants.GETXEROMETRICS_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETQUICKBOOKSHEALTH_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETQUICKBOOKSHEALTH_SUCCESS:
      return Object.assign({}, state, {
        payor_health: action.health, 
      });
    case integrationConstants.GETQUICKBOOKSHEALTH_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETXEROHEALTH_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETXEROHEALTH_SUCCESS:
      return Object.assign({}, state, {
        payor_health: action.health, 
      });
    case integrationConstants.GETXEROHEALTH_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.GETPAYEES_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.GETPAYEES_SUCCESS:
      return Object.assign({}, state, {
        payor_payees: action.payees, 
      });
    case integrationConstants.GETPAYEES_FAILURE:
      return Object.assign({}, state, {});
    //
    case integrationConstants.DISCONNECTINTEGRATION_REQUEST:
      return Object.assign({}, state, {});
    case integrationConstants.DISCONNECTINTEGRATION_SUCCESS:
      return Object.assign({}, state, {});
    case integrationConstants.DISCONNECTINTEGRATION_FAILURE:
      return Object.assign({}, state, {});
    //
    default:
      return state
  }
}