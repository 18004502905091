import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

// import Payees from './Payees';
import MetricsOverview from './MetricsOverview';
import InboundWebhooks from './InboundWebhooks';
// import Notifications from './Notifications';
import Settings from './Settings';
import { integrationActions } from '../_actions';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '85%',
    margin: '100px 20px 20px 20px',
  },
  paper: {
    width: '85%',
    margin: '100px 20px 20px 20px',
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabpanel: {
    width: '80%'
  },
  root: {
    marginTop: '20px',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export function Integration() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const integration_type = useSelector(state => state.integration.integration_type || '');
  const payor = useSelector(state => state.integration.payor || {});
  const selectedTab = useSelector(state => state.integration.integration_tab);

  if (!payor.id) {
    history.push('/');
  }

  useEffect(() => {
    dispatch(integrationActions.setIntegrationTab(0));
    if (integration_type === 'quickbooks') {
      dispatch(integrationActions.getQuickbooksMetrics(payor.payor_id, payor.id));
      dispatch(integrationActions.getQuickbooksHealth(payor.payor_id, payor.id));
    } else if (integration_type === 'xero') {
      dispatch(integrationActions.getXeroMetrics(payor.payor_id, payor.id));
      dispatch(integrationActions.getXeroHealth(payor.payor_id, payor.id));
    }
    
  }, [dispatch, payor, integration_type]);

  const handleChange = (event, newValue) => {
    dispatch(integrationActions.setIntegrationTab(newValue));
  };

  return (
    <div align="center">
      <main className={classes.layout}>
        <CssBaseline />

        <Button
          variant="outlined"
          color="default"
          className={classes.button}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => {history.push('/')}}
        >
          Back to Velo Payors
        </Button>

        <Paper className={classes.root}>
          <Tabs
              orientation="vertical"
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
              value={selectedTab}
              onChange={handleChange}
              className={classes.tabs}
          >
              <Tab label="Overview" {...a11yProps(0)} />
              <Tab label="Webhooks" {...a11yProps(1)} />
              {/* <Tab label="Payees" {...a11yProps(2)} /> */}
              <Tab label="Settings" {...a11yProps(3)} />
              {/* <Tab label="Notifications" {...a11yProps(4)} /> */}
          </Tabs>
          <TabPanel value={selectedTab} index={0} className={classes.tabpanel}>
            <MetricsOverview />
          </TabPanel>
          <TabPanel value={selectedTab} index={1} className={classes.tabpanel}>
            <InboundWebhooks />
          </TabPanel>
          <TabPanel value={selectedTab} index={2} className={classes.tabpanel}>
            {/* <Payees />
          </TabPanel>
          <TabPanel value={selectedTab} index={3} className={classes.tabpanel}> */}
            <Settings />
          </TabPanel>
          {/* <TabPanel value={selectedTab} index={4} className={classes.tabpanel}>
            <Notifications />
          </TabPanel> */}
        </Paper>
      </main>
    </div>
  );
}