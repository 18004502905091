import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import { authenticationActions, integrationActions } from '../_actions';
import { makeStyles } from '@material-ui/core/styles';
import Rules from './Rules';
import {
    Button,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import {
    Warning,
} from '@material-ui/icons';
import {
    green,
    grey,
  } from '@material-ui/core/colors';

const useStyles = makeStyles({
    root: {
        padding: '120px 0px 0px 0px',
        margin: '0px 20px 20px 20px',
        width: '75%'
    },
    payorpaper: {
        padding: '20px',
        marginBottom: '20px',
    },
    tablecontainer: {
        margin: '40px 20px 20px 20px',
        width: '90%'
    },
    table: {
        minWidth: 650,
    },
    quickbooksintegrations: {
        margin: '20px',
        padding: '30px 0px 30px 0px',
        border: '1px solid #eee',
        width: '90%'
    },
    xerointegrations: {
        margin: '20px',
        padding: '30px 0px 30px 0px',
        border: '1px solid #eee',
        width: '90%'
    },
    logo: {
        height: '60px',
        width: 'auto'
    },
    integration_active: {
        color: '#fff',
        backgroundColor: green[500],
    },
    integration_inactive: {
        color: '#fff',
        backgroundColor: grey[400],
    },
    connect_button: {
        height: '40px',
        cursor: 'pointer'
    }
});

export function Payors() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const loggedIn = useSelector(state => state.authentication.loggedIn);
    const payors = useSelector(state => state.integration.payors || []);

    if (!loggedIn) {
        authenticationActions.logout();
    }

    useEffect(() => {
        dispatch(integrationActions.getPayors());
    }, [dispatch]);

    const selectPayor = (t, p) => {
        dispatch(integrationActions.setPayorSession(t, p));
        history.push('/integration');
    }

    const renderActionButton = (type, row) => {
        if ((type === 'quickbooks' && row.quickbooks_rules.filter(r => !r.is_deleted).length === 0) || (type === 'xero' && row.xero_rules.filter(r => !r.is_deleted).length === 0)) {
            return <Tooltip title="WARNING: Without rules no payments will be processed." arrow>
                <IconButton aria-label="norules"  onClick={() => selectPayor(type, row)} className={classes.margin}>
                    <Warning color="secondary" fontSize="large" />
                </IconButton>
            </Tooltip>;
        } else {
            return <Button color="primary" onClick={() => selectPayor(type, row)} variant="outlined">VIEW</Button>;
        }
    }

    const renderActiveChip = (row) => {
        let label = row.is_active ? 'ACTIVE' : 'INACTIVE';
        if(row.is_deleted) {
            label = 'DISCONNECTED';
        }
        let color = row.is_active ? classes.integration_active : classes.integration_inactive;
        return <Chip label={label} className={color} />
    }

// ////////////////////////////////////////////////////////////////////////////////

    const renderQuickbooks = (integrations) => {
        if (integrations.length <= 0) {
            return;
        }
        return (
            <div>
            {integrations.map((integration, i) => (
                <div className={classes.quickbooksintegrations} key={integration.id}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <img alt="QuickBooks Logo" src="/img/quickbooks-logo-green.png" className={classes.logo} /> 
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" component="h6">
                                {integration.company_name}
                            </Typography>
                            <Typography variant="subtitle2" component="span">
                                QuickBooks ID: {integration.realm_id}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            {renderActiveChip(integration)}
                            <br/>
                            <Typography variant="subtitle2" component="span">
                                Created: <Moment date={new Date(integration.created_at)} format="MMMM DD, YYYY" />
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <div>
                                {renderActionButton('quickbooks',integration)}
                            </div> 
                        </Grid>
                    </Grid>

                    <Rules type='quickbooks' integration={integration} />
                </div>
            ))}
            </div>
        );
    }

////////////////////////////////////////////////////////////////////////////////

    const renderXero = (integrations) => {
        if (integrations.length <= 0) {
            return;
        }
        return (
            <div>
            {integrations.map((integration, i) => (
                <div className={classes.xerointegrations} key={integration.id}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <img alt="Xero Logo" src="/img/xero-logo-blue.png" className={classes.logo} /> 
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" component="h6">
                                {integration.company_name}
                            </Typography>
                            <Typography variant="subtitle2" component="span">
                                Xero ID: {integration.tenant_id}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            {renderActiveChip(integration)}
                            <br/>
                            <Typography variant="subtitle2" component="span">
                                Created: <Moment date={new Date(integration.created_at)} format="MMMM DD, YYYY" />
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <div>
                                {renderActionButton('xero',integration)}
                            </div> 
                        </Grid>
                    </Grid>

                    <Rules type='xero' integration={integration} />
                </div>
            ))}
            </div>
        );
    }

////////////////////////////////////////////////////////////////////////////////

    const renderSetupStep = (row) => {
        if (!row.id) {
            return <Grid item xs={6}>
                <Button color="primary" onClick={() => handleClickOpen(row)} variant="contained">SETUP INTEGRATION</Button>
            </Grid>;
        } else if (row.quickbooks.length === 0 && row.xero.length === 0 ) {
            return <Grid item xs={6}>
                <img src="/img/quickbooks-connect-green.png" alt="QuickBooks Logo" onClick={() => showQBWindow(row)} className={classes.connect_button} />
                &nbsp;
                <img src="/img/xero-connect-blue.png" alt="Xero Logo" onClick={() => showXeroWindow(row)} className={classes.connect_button} />
            </Grid>;
        } else {
            return <Grid item xs={6}>
                {renderActiveChip(row)}
                <br/>
                <Typography variant="subtitle2" component="span">
                    Created: <Moment date={new Date(row.created_at)} format="MMMM DD, YYYY" />
                </Typography>
            </Grid>;
        }
    }

    const [setuppayor, setSetupPayor] = React.useState({});
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (payor) => {
        setSetupPayor(payor);
        setOpen(true);
    };

    const handleClose = (agree) => {
        if (agree) {
            dispatch(integrationActions.connectVeloPayor(setuppayor.velo_payor_id));
        }
        setOpen(false);
    };

    const qb_redirecturl = useSelector(state => state.integration.qb_redirecturl || null);
    const xero_redirecturl = useSelector(state => state.integration.xero_redirecturl || null);

    const [qbPopup, setQBPopup] = useState(false);
    const [xeroPopup, setXeroPopup] = useState(false);

    let showQBWindow = function (payor) {
        setQBPopup(false);
        dispatch(integrationActions.getQuickbooksAuthCode(payor.id));
    }

    let showXeroWindow = function (payor) {
        setXeroPopup(false);
        dispatch(integrationActions.getXeroAuthCode(payor.id));
    }

    const listenToOAuthPopup = () => {
        var leftDomain = false;
        console.log('listenToOAuthPopup');
        var interval = setInterval(function() {
            console.log('listenToOAuthPopup >> setInterval', document.URL);
            try {
                console.log('listenToOAuthPopup >> setInterval >> try', popup_win.document.domain, document.domain);
                if (popup_win.document.domain === document.domain) {
                    if (leftDomain && popup_win.document.readyState === "complete") {
                        // we're here when the child window returned to our domain
                        clearInterval(interval);
                        // popup_win.postMessage({ message: "requestResult" }, "*");
                        if (popup_win.document.URL.includes('/callback')) {
                            popup_win.close();
                            window.location.href = '/';
                        }
                    }
                }
                else {
                    // this code should never be reached, 
                    // as the x-site security check throws
                    // but just in case
                    leftDomain = true;
                }
            }
            catch(e) {
                console.log('listenToOAuthPopup >> setInterval >> catch', e);
                // we're here when the child window has been navigated away or closed
                if (popup_win.closed) {
                    clearInterval(interval);
                    // alert("closed");
                    return; 
                }
                // navigated to another domain  
                leftDomain = true;
            }
        }, 500);
    };

    let popup_win;
    let popup_params = "location=1,width=800,height=650,left=" + (window.innerWidth - 800) / 2 + ",top=" + (window.innerHeight - 650) / 2;
    if (!qbPopup && qb_redirecturl !== null) {
        popup_win = window.open(qb_redirecturl, 'connectPopup', popup_params);
        setQBPopup(true);
        listenToOAuthPopup();
    }
    
    if (!xeroPopup && xero_redirecturl !== null) {
        popup_win = window.open(xero_redirecturl, 'connectPopup', popup_params);
        setXeroPopup(true);
        listenToOAuthPopup();
    }

    const renderAdditionalIntegration = (row) => {
        if (row.quickbooks.length === 0 && row.xero.length === 0) {
            return "";
        }
        return <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" component="span">
                        Create Additional Integration
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <img src="/img/quickbooks-connect-green.png" alt="QuickBooks Logo" onClick={() => showQBWindow(row)} className={classes.connect_button} />
                    &nbsp;
                    <img src="/img/xero-connect-blue.png" alt="Xero Logo" onClick={() => showXeroWindow(row)} className={classes.connect_button} />
                </Grid>
            </Grid>;
    }
    
    return (
        <div align="center">
            <div className={classes.root}>
                {payors.map((row, i) => (
                    <Paper className={classes.payorpaper} key={row.velo_payor_id}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="h5" component="h5">
                                    {row.name}
                                </Typography>
                                <Typography variant="subtitle2" component="span">
                                    Velo ID: {row.velo_payor_id}
                                </Typography>
                            </Grid>
                            {renderSetupStep(row)}
                        </Grid>
                        {renderQuickbooks(row.quickbooks)}
                        {renderXero(row.xero)}
                        {renderAdditionalIntegration(row)}
                    </Paper>
                ))}
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Use Velo's Accounting Integration service?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You as a representative of: {setuppayor.name} <br/><br/> Acknowledges to allow Velo submit payments based on actions performed in a 3rd party accounting platform. You are opting into an agreement.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleClose(false)} color="secondary" variant="contained">
                    Disagree
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" variant="contained" autoFocus>
                    I AGREE
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
