import { appConstants } from '../_constants';

const initialState = { callsInFlight: 0 };

export function app(state = initialState, action) {
  switch (action.type) {
    //
    case appConstants.INC_CALLSINFLIGHT:
      const a = state.callsInFlight++;
      return Object.assign({}, state, {
        callsInFlight: a
      });
    case appConstants.DEC_CALLSINFLIGHT:
      const d = ((state.callsInFlight - 1) < 0) ? 0 : (state.callsInFlight - 1);
      return Object.assign({}, state, {
        callsInFlight: d
      });
    case appConstants.CLEAR_CALLSINFLIGHT:
      return Object.assign({}, state, {
        callsInFlight: 0
      });
    //
    default:
      return state
  }
}