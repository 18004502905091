import { authenticationConstants } from '../_constants';

let token = sessionStorage.getItem('access_token');
const defaultState = {loggedIn: false, authFailure: false, mfaReady: false, mfaType: null, selfinfo: null };
const initialState = token ? { loggedIn: true, authFailure: false, mfaReady: false, mfaType: null, selfinfo: null } : defaultState;

export function authentication(state = initialState, action) {
  switch (action.type) {
    //
    case authenticationConstants.LOGIN_REQUEST:
      return Object.assign({}, state, {
        loggingIn: true, 
        authFailure: false,
        mfaReady: false,
        payorId: null
      });
    case authenticationConstants.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loggingIn: false,
        loggedIn: false,
        mfaReady: true, 
        authFailure: false,
        mfaType: action.user.user_info.mfa_details.mfa_type
      });
    case authenticationConstants.LOGIN_FAILURE:
      return Object.assign({}, state, {
        loggingIn: false, 
        mfaReady: false,
        payorId: null,
        authFailure: true
      });
    //
    case authenticationConstants.MFA_REQUEST:
      return Object.assign({}, state, {
        loggingIn: true, 
        verifyingMFA: true,
        authFailure: false
      });
    case authenticationConstants.MFA_SUCCESS:
      return Object.assign({}, state, {
        loggingIn: false, 
        verifyingMFA: false,
        authFailure: false,
        loggedIn: true
      });
    case authenticationConstants.MFA_FAILURE:
      return Object.assign({}, state, {
        loggingIn: false, 
        verifyingMFA: false,
        authFailure: true
      });
    //
    case authenticationConstants.LOGOUT:
      return Object.assign({}, {
        loggingIn: false,
        loggedIn: false,
        verifyingMFA: false, 
        mfaReady: false,
        mfaType: null,
        authFailure: false
      });
    //
    case authenticationConstants.REFRESH_REQUEST:
      return state
    case authenticationConstants.REFRESH_SUCCESS:
      return state
    case authenticationConstants.REFRESH_FAILURE:
      return Object.assign({}, state, defaultState);
    //
    case authenticationConstants.SELF_REQUEST:
      return state
    case authenticationConstants.SELF_SUCCESS:
      return Object.assign({}, state, {
        selfinfo: action.user
      });
    case authenticationConstants.SELF_FAILURE:
      return state
    //
    default:
      return state
  }
}