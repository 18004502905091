export const authenticationService = {
    login,
    mfa,
    logout,
    refresh,
    getSelf,
};


function login(username, password) {
    const params = { grant_type: "password", username: username, password: password };
    const searchParams = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Authorization': `Basic ${process.env.REACT_APP_API_AUTH}`
        },
        body: searchParams
    };
    return fetch(`${process.env.REACT_APP_API_URL}/authenticate`, requestOptions)
        .then(response => {
            if (response.ok) {
                return response.json();
              } else {
                throw new Error('Something went wrong');
              }
        })
        .then(payload => {
            if (payload.access_token) {
                sessionStorage.removeItem('access_expires');
                sessionStorage.setItem('mfa_token', payload.access_token);
                sessionStorage.setItem('mfa_type', payload.user_info.mfa_details.mfa_type);
            }
            return payload;
        }).catch(() => {
            return Promise.reject();
        });
}

function mfa(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${sessionStorage.getItem('mfa_token')}`
        },
        body: JSON.stringify({ otpValue: token })
    };
    return fetch(`${process.env.REACT_APP_API_URL}/validate`, requestOptions)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong');
            }
        })
        .then(payload => {
            if (payload.access_token) {
                sessionStorage.removeItem('mfa_token');
                sessionStorage.removeItem('mfa_type');
                sessionStorage.setItem('access_token', payload.access_token);
                sessionStorage.setItem('access_expires', payload.expires);
            }
            return payload;
        }).catch(() => {
            return Promise.reject()
        });
}

function logout(auto) {
    // remove user from local storage to log user out
    sessionStorage.removeItem('mfa_token');
    sessionStorage.removeItem('mfa_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('access_expires');
    if (auto) {
        window.alert('Your session has ended. Please log in again.');
    }
    window.location.href = '/';
}

function refresh() {
    const params = { grant_type: "refresh_token" };
    const searchParams = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
        },
        body: searchParams
    };
    return fetch(`${process.env.REACT_APP_API_URL}/authenticate`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            if (payload.access_token) {
                sessionStorage.removeItem('mfa_token');
                sessionStorage.removeItem('mfa_type');
                sessionStorage.setItem('access_token', payload.access_token);
                sessionStorage.setItem('access_expires', payload.expires);
            }
            return payload;
        });
}

function getSelf(token) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/self`, requestOptions)
        .then(handleResponse)
        .then(payload => {
            return payload;
        });
}

function handleResponse(response) {
    if (response.status === 401) {
        logout();
    }
    return response.json().then(data => {
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    },
    error => {
        return;
    });
}