import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import moment from 'moment';
import Chart from "react-apexcharts";

import {
    Typography,
    Button,
    Grid, 
    Divider,
    Chip,
} from '@material-ui/core';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import {
    green,
    grey,
  } from '@material-ui/core/colors';
  
import { integrationActions } from '../_actions';


const useStyles = makeStyles((theme) => ({
    webhook_chart: {
        width: '100%',
        height: '300px',
        margin: '20px 0px',
    },
    break: {
      margin: '40px 0px'
    },
    logo: {
        height: '60px',
        width: 'auto'
    },
    integration_active: {
        color: '#fff',
        backgroundColor: green[500],
    },
    integration_inactive: {
        color: '#fff',
        backgroundColor: grey[400],
    },
}));

export default function IntegrationOverview() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const integration_type = useSelector(state => state.integration.integration_type || '');
    const integration = useSelector(state => state.integration.integration || {});
    const payments = useSelector(state => state.integration.payor_metrics.payments || []);
    
    useEffect(() => {}, [dispatch]);

    let cats = [];
    let data = [];
    for (let i = 30; i >= 0; i--) {
        let l = moment().subtract(i, 'days').format('MM/DD');
        cats.push(l);
        let aggreg = 0;
        for (let j = 0; j < payments.length; j++) {
            let d = moment(payments[j].ts).format('MM/DD');
            if (d === l) {
                aggreg++;
            }
        }
        data.push(aggreg);
    }
    
    let series = [{
        name: 'Inbound',
        data: data
    }];
    let options = {
        chart: {
        height: 350,
        type: 'bar',
        },
        plotOptions: {
        bar: {
            dataLabels: {
            position: 'top', // top, center, bottom
            },
        }
        },
        dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val;
        },
        offsetY: -20,
        style: {
            fontSize: '12px',
            colors: ["#304758"]
        }
        },
        
        xaxis: {
        categories: cats,
        position: 'top',
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
        crosshairs: {
            fill: {
            type: 'gradient',
            gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
            }
            }
        },
        tooltip: {
            enabled: true,
        }
        },
        yaxis: {
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false,
        },
        labels: {
            show: false,
            formatter: function (val) {
            return val;
            }
        }
        
        },
        title: {
        text: 'Webhooks (Last 30 Days)',
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
            color: '#444'
        }
        }
    };

      

    const handleViewWebhooks = () => {
        dispatch(integrationActions.setIntegrationTab(1));
    }

    const serviceName = (integration) => {
        if (integration_type === 'quickbooks') {
          return 'QuickBooks ID: '+integration.realm_id;
        } else if (integration_type === 'xero') {
          return 'Xero ID: '+integration.tenant_id;
        }
    }
    const renderActiveChip = (row) => {
        let label = row.is_active ? 'ACTIVE' : 'INACTIVE';
        let color = row.is_active ? classes.integration_active : classes.integration_inactive;
        return <Chip label={label} className={color} />
    }

    const renderLogo = () => {
        if (integration_type === 'quickbooks') {
            return <img alt="QuickBooks Logo" src="/img/quickbooks-logo-green.png" className={classes.logo} /> ;
        } else if (integration_type === 'xero') {
            return <img alt="Xero Logo" src="/img/xero-logo-blue.png" className={classes.logo} /> ;
        }
    }
    
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    {renderLogo()}
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" component="span">
                        {integration.company_name}
                    </Typography>
                    <br/>
                    <Typography variant="subtitle2" component="span">
                        {serviceName(integration)}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    {renderActiveChip(integration)}
                    <br/>
                    <Typography variant="subtitle2" component="span">
                        Created: <Moment date={new Date(integration.created_at)} format="MMMM DD, YYYY" />
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>

            <Divider className={classes.break} />

            <Typography component="span" variant="h6" gutterBottom>
                Webhooks
            </Typography>
            <div className={classes.webhook_chart}>
                <Chart options={options} series={series} type="bar" height={350} />
            </div>
            <br/><br/>
            <div align="right">
                <Button color="primary" endIcon={<KeyboardArrowRightIcon />} onClick={() => {handleViewWebhooks()}}>View Webhooks</Button>
            </div>
        </React.Fragment>
    );
}