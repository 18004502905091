import { integrationConstants } from '../_constants';
import { integrationService } from '../_services';

export const integrationActions = {
    getPayors,
    connectVeloPayor,
    setPayorSession,
    generateVeloPayorToken,
    getQuickbooksAuthCode,
    getXeroAuthCode,
    getVeloPayorSourceAccounts,
    getQuickbooksAccounts,
    getXeroAccounts,
    updateQuickbooksIntegration,
    updateXeroIntegration,
    getQuickbooksIntegration,
    getXeroIntegration,
    getQuickbooksWebhooks,
    getXeroWebhooks,
    getQuickbooksMetrics,
    getXeroMetrics,
    getQuickbooksHealth,
    getXeroHealth,
    verifyQuickbooksIntegration,
    verifyXeroIntegration,
    setIntegrationTab,
    createQuickbooksRule,
    deleteQuickbooksRule,
    createXeroRule,
    deleteXeroRule,
    getPayees,
    disconnectIntegration,
};

function getPayors() {
    return dispatch => {
        dispatch(request());

        integrationService.getPayors()
            .then(
                payors => { 
                    dispatch(success(payors));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETPAYORS_REQUEST }; }
    function success(payors) { return { type: integrationConstants.GETPAYORS_SUCCESS, payors } }
    function failure(error) { return { type: integrationConstants.GETPAYORS_FAILURE, error } }
}

function connectVeloPayor(payor_id) {
    return dispatch => {
        dispatch(request());

        integrationService.connectVeloPayor(payor_id)
            .then(
                payor => { 
                    dispatch(success(payor));
                    setTimeout(() => {
                        dispatch(integrationActions.generateVeloPayorToken(payor.id));
                    }, 1000);
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.CONNECTVELOPAYOR_REQUEST }; }
    function success(payor) { return { type: integrationConstants.CONNECTVELOPAYOR_SUCCESS, payor } }
    function failure(error) { return { type: integrationConstants.CONNECTVELOPAYOR_FAILURE, error } }
}

function setPayorSession(t, p) {
    return dispatch => {
        dispatch( { type: integrationConstants.SETPAYOR_CONFIGURE, integration_type: t, payor: p } );
    }
}

function generateVeloPayorToken(payor_id) {
    return dispatch => {
        dispatch(request());

        integrationService.generateVeloPayorToken(payor_id)
            .then(
                () => { 
                    dispatch(success());
                    dispatch(integrationActions.getPayors());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GENERATEVELOPAYORTOKEN_REQUEST }; }
    function success() { return { type: integrationConstants.GENERATEVELOPAYORTOKEN_SUCCESS } }
    function failure(error) { return { type: integrationConstants.GENERATEVELOPAYORTOKEN_FAILURE, error } }
}

function getQuickbooksAuthCode(payor_id) {
    return dispatch => {
        dispatch(request());

        integrationService.getQuickbooksAuthCode(payor_id)
            .then(
                redirecturl => { 
                    dispatch(success(redirecturl));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETQBAUTHCODE_REQUEST }; }
    function success(redirecturl) { return { type: integrationConstants.GETQBAUTHCODE_SUCCESS, redirecturl } }
    function failure(error) { return { type: integrationConstants.GETQBAUTHCODE_FAILURE, error } }
}

function getXeroAuthCode(payor_id) {
    return dispatch => {
        dispatch(request());

        integrationService.getXeroAuthCode(payor_id)
            .then(
                redirecturl => { 
                    dispatch(success(redirecturl));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETXEROAUTHCODE_REQUEST }; }
    function success(redirecturl) { return { type: integrationConstants.GETXEROAUTHCODE_SUCCESS, redirecturl } }
    function failure(error) { return { type: integrationConstants.GETXEROAUTHCODE_FAILURE, error } }
}

function getVeloPayorSourceAccounts(payor_id) {
    return dispatch => {
        dispatch(request());

        integrationService.getVeloPayorSourceAccounts(payor_id)
            .then(
                accounts => { 
                    dispatch(success(accounts));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETVELOPAYORSOURCEACCOUNTS_REQUEST }; }
    function success(accounts) { return { type: integrationConstants.GETVELOPAYORSOURCEACCOUNTS_SUCCESS, accounts } }
    function failure(error) { return { type: integrationConstants.GETVELOPAYORSOURCEACCOUNTS_FAILURE, error } }
}

function getQuickbooksAccounts(payor_id, integration_id) {
    return dispatch => {
        dispatch(request());

        integrationService.getQuickbooksAccounts(payor_id, integration_id)
            .then(
                accounts => { 
                    dispatch(success(accounts));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETQUICKBOOKSACCOUNTS_REQUEST }; }
    function success(accounts) { return { type: integrationConstants.GETQUICKBOOKSACCOUNTS_SUCCESS, integration_id, accounts } }
    function failure(error) { return { type: integrationConstants.GETQUICKBOOKSACCOUNTS_FAILURE, error } }
}

function getXeroAccounts(payor_id, integration_id) {
    return dispatch => {
        dispatch(request());

        integrationService.getXeroAccounts(payor_id, integration_id)
            .then(
                (accounts) => { 
                    dispatch(success(accounts));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETXEROACCOUNTS_REQUEST }; }
    function success(accounts) { return { type: integrationConstants.GETXEROACCOUNTS_SUCCESS, integration_id, accounts } }
    function failure(error) { return { type: integrationConstants.GETXEROACCOUNTS_FAILURE, error } }
}

function updateQuickbooksIntegration(payor_id, integration_id, payload) {
    return dispatch => {
        dispatch(request());

        integrationService.updateQuickbooksIntegration(payor_id, integration_id, payload)
            .then(
                () => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.UPDATEQUICKBOOKSINTEGRATION_REQUEST }; }
    function success() { return { type: integrationConstants.UPDATEQUICKBOOKSINTEGRATION_SUCCESS } }
    function failure(error) { return { type: integrationConstants.UPDATEQUICKBOOKSINTEGRATION_FAILURE, error } }
}

function updateXeroIntegration(payor_id, integration_id, payload) {
    return dispatch => {
        dispatch(request());

        integrationService.updateXeroIntegration(payor_id, integration_id, payload)
            .then(
                () => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.UPDATEXEROINTEGRATION_REQUEST }; }
    function success() { return { type: integrationConstants.UPDATEXEROINTEGRATION_SUCCESS } }
    function failure(error) { return { type: integrationConstants.UPDATEXEROINTEGRATION_FAILURE, error } }
}

function getQuickbooksIntegration(payor_id) {
    return dispatch => {
        dispatch(request());

        integrationService.getQuickbooksIntegration(payor_id)
            .then(
                integration => { 
                    dispatch(success(integration));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETQUICKBOOKSINTEGRATION_REQUEST }; }
    function success(integration) { return { type: integrationConstants.GETQUICKBOOKSINTEGRATION_SUCCESS, integration } }
    function failure(error) { return { type: integrationConstants.GETQUICKBOOKSINTEGRATION_FAILURE, error } }
}

function getXeroIntegration(payor_id) {
    return dispatch => {
        dispatch(request());

        integrationService.getXeroIntegration(payor_id)
            .then(
                integration => { 
                    dispatch(success(integration));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETXEROINTEGRATION_REQUEST }; }
    function success(integration) { return { type: integrationConstants.GETXEROINTEGRATION_SUCCESS, integration } }
    function failure(error) { return { type: integrationConstants.GETXEROINTEGRATION_FAILURE, error } }
}

function getQuickbooksWebhooks(payor_id, id) {
    return dispatch => {
        dispatch(request());

        integrationService.getQuickbooksWebhooks(payor_id, id)
            .then(
                webhooks => { 
                    dispatch(success(webhooks));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETQUICKBOOKSWEBHOOKS_REQUEST }; }
    function success(webhooks) { return { type: integrationConstants.GETQUICKBOOKSWEBHOOKS_SUCCESS, webhooks } }
    function failure(error) { return { type: integrationConstants.GETQUICKBOOKSWEBHOOKS_FAILURE, error } }
}

function getXeroWebhooks(payor_id, id) {
    return dispatch => {
        dispatch(request());

        integrationService.getXeroWebhooks(payor_id, id)
            .then(
                webhooks => { 
                    dispatch(success(webhooks));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETXEROWEBHOOKS_REQUEST }; }
    function success(webhooks) { return { type: integrationConstants.GETXEROWEBHOOKS_SUCCESS, webhooks } }
    function failure(error) { return { type: integrationConstants.GETXEROWEBHOOKS_FAILURE, error } }
}
// 
function getQuickbooksMetrics(payor_id, id) {
    return dispatch => {
        dispatch(request());

        integrationService.getQuickbooksMetrics(payor_id, id)
            .then(
                metrics => { 
                    dispatch(success(metrics));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETQUICKBOOKSMETRICS_REQUEST }; }
    function success(metrics) { return { type: integrationConstants.GETQUICKBOOKSMETRICS_SUCCESS, metrics } }
    function failure(error) { return { type: integrationConstants.GETQUICKBOOKSMETRICS_FAILURE, error } }
}

function getXeroMetrics(payor_id, id) {
    return dispatch => {
        dispatch(request());

        integrationService.getXeroMetrics(payor_id, id)
            .then(
                metrics => { 
                    dispatch(success(metrics));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETXEROMETRICS_REQUEST }; }
    function success(metrics) { return { type: integrationConstants.GETXEROMETRICS_SUCCESS, metrics } }
    function failure(error) { return { type: integrationConstants.GETXEROMETRICS_FAILURE, error } }
}
// 
function getQuickbooksHealth(payor_id, id) {
    return dispatch => {
        dispatch(request());

        integrationService.getQuickbooksHealth(payor_id, id)
            .then(
                health => { 
                    dispatch(success(health));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETQUICKBOOKSHEALTH_REQUEST }; }
    function success(health) { return { type: integrationConstants.GETQUICKBOOKSHEALTH_SUCCESS, health } }
    function failure(error) { return { type: integrationConstants.GETQUICKBOOKSHEALTH_FAILURE, error } }
}

function getXeroHealth(payor_id, id) {
    return dispatch => {
        dispatch(request());

        integrationService.getXeroHealth(payor_id, id)
            .then(
                health => { 
                    dispatch(success(health));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETXEROHEALTH_REQUEST }; }
    function success(health) { return { type: integrationConstants.GETXEROHEALTH_SUCCESS, health } }
    function failure(error) { return { type: integrationConstants.GETXEROHEALTH_FAILURE, error } }
}
function verifyQuickbooksIntegration(payor_id) {
    return dispatch => {
        dispatch(request());

        integrationService.verifyQuickbooksIntegration(payor_id)
            .then(
                accounts => { 
                    dispatch(success(accounts));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETQUICKBOOKSINTEGRATION_REQUEST }; }
    function success(accounts) { return { type: integrationConstants.GETQUICKBOOKSINTEGRATION_SUCCESS, accounts } }
    function failure(error) { return { type: integrationConstants.GETQUICKBOOKSINTEGRATION_FAILURE, error } }
}

function verifyXeroIntegration(payor_id) {
    return dispatch => {
        dispatch(request());

        integrationService.verifyXeroIntegration(payor_id)
            .then(
                accounts => { 
                    dispatch(success(accounts));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETXEROINTEGRATION_REQUEST }; }
    function success(accounts) { return { type: integrationConstants.GETXEROINTEGRATION_SUCCESS, accounts } }
    function failure(error) { return { type: integrationConstants.GETXEROINTEGRATION_FAILURE, error } }
}

function setIntegrationTab(t) {
    return dispatch => {
        dispatch( { type: integrationConstants.SETINTEGRATION_TAB, tab: t } );
    }
}


function createQuickbooksRule(payor_id, integration_id, payload) {
    return dispatch => {
        dispatch(request());

        integrationService.createQuickbooksRule(payor_id, integration_id, payload)
            .then(
                () => { 
                    dispatch(success());
                    dispatch(getPayors());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.CREATEQUICKBOOKSRULE_REQUEST }; }
    function success() { return { type: integrationConstants.CREATEQUICKBOOKSRULE_SUCCESS } }
    function failure(error) { return { type: integrationConstants.CREATEQUICKBOOKSRULE_FAILURE, error } }
}

function deleteQuickbooksRule(id, payor_id, integration_id) {
    return dispatch => {
        dispatch(request());

        integrationService.deleteQuickbooksRule(id, payor_id, integration_id)
            .then(
                () => { 
                    dispatch(success());
                    dispatch(getPayors());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.DELETEQUICKBOOKSRULE_REQUEST }; }
    function success() { return { type: integrationConstants.DELETEQUICKBOOKSRULE_SUCCESS } }
    function failure(error) { return { type: integrationConstants.DELETEQUICKBOOKSRULE_FAILURE, error } }
}

function createXeroRule(payor_id, integration_id, payload) {
    return dispatch => {
        dispatch(request());

        integrationService.createXeroRule(payor_id, integration_id, payload)
            .then(
                () => { 
                    dispatch(success());
                    dispatch(getPayors());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.CREATEXERORULE_REQUEST }; }
    function success() { return { type: integrationConstants.CREATEXERORULE_SUCCESS } }
    function failure(error) { return { type: integrationConstants.CREATEXERORULE_FAILURE, error } }
}

function deleteXeroRule(id, payor_id, integration_id) {
    return dispatch => {
        dispatch(request());

        integrationService.deleteXeroRule(id, payor_id, integration_id)
            .then(
                () => { 
                    dispatch(success());
                    dispatch(getPayors());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.DELETEXERORULE_REQUEST }; }
    function success() { return { type: integrationConstants.DELETEXERORULE_SUCCESS } }
    function failure(error) { return { type: integrationConstants.DELETEXERORULE_FAILURE, error } }
}

function getPayees(payor_id) {
    return dispatch => {
        dispatch(request());

        integrationService.getPayees(payor_id)
            .then(
                payees => { 
                    dispatch(success(payees));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.GETPAYEES_REQUEST }; }
    function success(payees) { return { type: integrationConstants.GETPAYEES_SUCCESS, payees } }
    function failure(error) { return { type: integrationConstants.GETPAYEES_FAILURE, error } }
}

function disconnectIntegration(payor_id, integration_type, integration_id) {
    return dispatch => {
        dispatch(request());

        integrationService.disconnectIntegration(payor_id, integration_type, integration_id)
            .then(
                payees => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: integrationConstants.DISCONNECTINTEGRATION_REQUEST }; }
    function success() { return { type: integrationConstants.DISCONNECTINTEGRATION_SUCCESS,  } }
    function failure(error) { return { type: integrationConstants.DISCONNECTINTEGRATION_FAILURE, error } }
}
