import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import { Switch } from 'react-router-dom';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import PrivateRoute from './PrivateRoute';
import { Payors}  from './Payors';
import { Integration}  from './Integration';
import { authenticationActions } from '../_actions';
import logo from '../logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar:{
    backgroundColor: "#F8F9FA",
    color: '#fff',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: '#000',
  },
  icon: {
    color: '#000'
  }
}));

export function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const [inactivityMonitor, setInactivityMonitor] = React.useState(false);

  useEffect(() => {
      dispatch(authenticationActions.getSelf());
  }, [dispatch]);

  if (!loggedIn) {
    return <Redirect to='/login' />
  }

  if (!inactivityMonitor) {
    setInactivityMonitor(true);
    const EXPIRES_BEFORE = 150; // seconds prior to token expiration we should auto refresh an active session
    const EXPIRE_CHECK_INTERVAL = 30; // seconds interval to check for activity
    const INACTIVITY_LIMIT = 30; // mins of inactivity allowed

    // this will be a tracker on last time user did anything
    let lastActiveTimestamp = Math.floor(Date.now() / 1000);
    const activityEvents = ['mousemove', 'keypress'];
    const handleActivity = () => {
      lastActiveTimestamp = Math.floor(Date.now() / 1000);
    };
    activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, handleActivity);
    });

    // every ? seconds check to see if the token has expired or is going to
    const inactivityCheckInterval = EXPIRE_CHECK_INTERVAL * 1000;
    const inactivityTimeout = function(){
      checkTokenExpiration();
    }
    setInterval(inactivityTimeout, inactivityCheckInterval);

    // if token expired or expires within next ? seconds ...logout or refresh token in background
    const checkTokenExpiration = () => {
      const tokenExpires = sessionStorage.getItem('access_expires');
      const now = Math.floor(Date.now() / 1000);
      const inactivityLimit = (lastActiveTimestamp + (INACTIVITY_LIMIT * 60));
      // if expired call logout action
      // or if user hasnt been active ... logout
      if (tokenExpires < now || inactivityLimit < now ) { 
        dispatch(authenticationActions.logout(true));
      } else if((tokenExpires - EXPIRES_BEFORE) < now) {
        // if token expiring soon refresh in background
        dispatch(authenticationActions.refresh());
      }
    };
    // on initial load check if token is expired
    checkTokenExpiration();
  }


  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt="Velo Logo" className='headerLogo' />

          <Typography variant="h6" className={classes.title}>
            
          </Typography>
          
          <Tooltip title='Logout' arrow>
            <IconButton className={classes.icon} onClick={() => dispatch(authenticationActions.logout())} >
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Switch>
        <PrivateRoute path="/integration" component={Integration} requirement="access_token" />  
        <PrivateRoute exact path="/" component={Payors} requirement="access_token" />
      </Switch>

    </div>
  )
}


