import { appConstants } from '../_constants';

export const appActions = {
    incCallInFlight,
    decCallInFlight,
    clearCallInFlight
};

function incCallInFlight() {
    return dispatch => {
        dispatch({ type: appConstants.INC_CALLSINFLIGHT });
    };
}
function decCallInFlight() {
    return dispatch => {
        dispatch({ type: appConstants.DEC_CALLSINFLIGHT });
    };
}
function clearCallInFlight() {
    return dispatch => {
        dispatch({ type: appConstants.CLEAR_CALLSINFLIGHT });
    };
}