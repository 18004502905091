import React from 'react';
import { useSelector } from "react-redux";
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './_components/PrivateRoute';
import { Login } from './_components/Login';
import { MFA } from './_components/MFA';
import { Dashboard } from './_components/Dashboard';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0077ba',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export function App() {
  const classes = useStyles();
  const callsInFlight = useSelector(state => state.app.callsInFlight);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router hashType="slash">
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/mfa" component={MFA} requirement="mfa_token" />
            <PrivateRoute path="/" component={Dashboard} requirement="access_token" />
          </Switch>
        </Router>
        <Backdrop className={classes.backdrop} open={(callsInFlight > 0)? true: false}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </div>
  );
}